@import "minima";

.box {
  border: 1px solid black;
  border-radius: 5px;
  padding: 11px 10px 3px 10px;
  margin-bottom: 10px;
  &.box-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
    > p:first-child::before {
      content: "⚠️ "
    }
  }
  &.box-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
    > p:first-child::before {
      content: "ℹ️ ";
    }
  }
  &.box-tip {
    color: #244025;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    > p:first-child::before {
      content: "📗 ";
    }
  }
  p {
    margin: 0;
  }
  p:first-child {
    margin-bottom: 4px;
    &::before {
      padding-right: 2px;
    }
  }
  .katex-html {
    color: black;
  }
}
